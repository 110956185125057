.slanted {
  text-transform: uppercase;
  transform: rotate(-5deg) skew(-5deg);
  background-color: #ffc;
  min-width: 100vw;
  padding: 4rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  position: relative;
}

.App-logo-clicked {
  animation-name: App-logo-spin;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

@keyframes App-logo-spin {

  0% {
    transform: rotate(0deg);
    /* background-color: red; */
    left: 0px;
    top: 0px;
  }

  20% {
    transform: rotate(360deg);
    /* background-color: yellow; */
    left: -35vw;
    top: 35vh;
  }

  35% {
    transform: rotate(0deg);
    /* background-color: blue; */
    left: 35vw;
    top: 35vh;
  }

  60% {
    transform: rotate(360deg);
    /* background-color: green; */
    left: 35vw;
    top: -35vh;
  }

  80% {
    transform: rotate(0deg);
    /* background-color: green; */
    left: -35vw;
    top: -35vh;
  }

  100% {
    transform: rotate(360deg);
    /* background-color: red; */
    left: 0px;
    top: 0px;
  }

}
